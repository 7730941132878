import React from 'react'
import Layout from 'layout/base'
import Seo from 'components/seo'
import PageTemplate from 'templates/pageTemplate'
import { ButtonSmall, UnderBarHeading } from 'components/atoms'
import SingleTemplate from 'templates/singleTemplate'
import { graphql } from 'gatsby'
import { siteStructure } from 'utils/site'
import type { TypeBreadcrumb } from 'types/breadcrumb'
import globalTitle from 'utils/globalTitle'

interface NewsSinglePageProps {
  data: {
    wpPost: {
      readonly title: string
      readonly databaseId: string
      readonly date: string
      readonly content: string
      readonly slug: string
    }
  }
  pageContext: TypeBreadcrumb
  location: Location
}

const NewsSinglePage = ({ data, location }: NewsSinglePageProps): JSX.Element => {
  // TODO: パンくずリストをハードコーディングなのでpageContextを使う形に書き換えたい
  const breadcrumbItems = [
    { pathname: '/', crumbLabel: 'ホーム' },
    { pathname: '/news/', crumbLabel: siteStructure['news']?.name },
    { pathname: `/news/${data.wpPost.slug}/`, crumbLabel: data.wpPost.title },
  ]

  return (
    <Layout pageType="page" breadcrumbItems={breadcrumbItems} ctaType="type1">
      <Seo
        title={`${data.wpPost.title}${globalTitle(location.pathname)}`}
        description="成績アップを目指すためのオンライン個別指導塾、Fit NET STUDYのお知らせ詳細ページです。"
        path={location.pathname}
      />

      <SingleTemplate headingText="お知らせ" titleText={data.wpPost.title} timeText={data.wpPost.date} contentText={data.wpPost.content} />
    </Layout>
  )
}

export default NewsSinglePage

export const query = graphql`
  query ($slug: String!) {
    wpPost(slug: { eq: $slug }) {
      slug
      content
      title
      databaseId
      date(formatString: "YYYY/MM/DD")
    }
  }
`
